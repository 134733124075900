import React, { useState, useEffect } from 'react';
import { Popconfirm, Button, Empty, Row, Col, Modal, Form, Input, Divider } from 'antd';
import { isEmpty } from 'underscore';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import dayjs from 'dayjs';
import { Link, useLocation } from 'react-router-dom';

import {
    DeleteOutlined,
    ReadOutlined,
    LoginOutlined,
} from '@ant-design/icons';

import useUser from '../../core/user/useUser';
import {
    removeGuestBookRecordRequest,
    sendGuestBookRecordRequest,
} from '../../core/grave/graveActions';

import {
    setRemeberPathRequest,
} from '../../core/user/userActions';

import './guestBook.scss';

const GuestBook = ({ disableAdmin }) => {
    const { user } = useUser();
    const grave = useSelector(state => state.grave);
    const selectedGravePeople = grave?.selectedGravePeople;
    const dispatch = useDispatch();
    const [modalVisible, setModalVisible] = useState(false);
    const [displayForm, setDisplayForm] = useState(true);
    const [messageSent] = useState(grave.messageSent);
    const formRef = React.useRef(null);
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const location = useLocation();

    const onFinish = (formData) => {
        const validate = true;

        if (validate) {
            const data = {
                message: formData.message,
                grave_people_id: selectedGravePeople?.id,
            };

            dispatch(sendGuestBookRecordRequest(data));
        }
    };

    const onFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    };

    useEffect(() => {
        if (messageSent !== grave.messageSent) {
            setTimeout(() => formRef.current?.resetFields(), 100);
            setDisplayForm(false);
        }
    }, [grave.messageSent]);

    const renderGuestBookItem = (guestBookItem) => (
        <div className="guest-book-item" key={`guest-book-item-${guestBookItem.id}`}>
            <Row>
                <Col span={12}>
                    <h4 className="guest-book-title">{guestBookItem.userName}</h4>
                </Col>
                <Col span={12}>
                    <p className="guest-book-date">
                        {!disableAdmin
                            ? guestBookItem.create_time
                            : dayjs(grave?.selectedGravePeople?.birth_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')}
                    </p>
                </Col>
            </Row>
            <div className="guest-book-text">{guestBookItem.text}</div>
            {!disableAdmin
                ? (
                    <Popconfirm
                        title="Ar tikrai norite ištrinti šį įrašą?"
                        onConfirm={() => { dispatch(removeGuestBookRecordRequest({ grave_people_id: grave?.selectedGravePeople?.id, guest_book_record_id: guestBookItem.id })); }}
                        okText="Taip"
                        cancelText="Ne">
                        <Button icon={<DeleteOutlined />}>Pašalinti</Button>
                    </Popconfirm>
                )
                : null}
        </div>
    );

    return (
        <div className="guest-book-wrapper">
            <div className="guest-book-open-modal-wrapper">
                <Button type="primary" icon={<ReadOutlined />} onClick={() => { setModalVisible(true); }} className="guest-book-open-modal-button">
                    Palikite atminimo įrašą
                </Button>
            </div>
            {isEmpty(selectedGravePeople.guestBookRecords) ? <Empty description="Įrašų nėra" /> : null }
            {selectedGravePeople?.guestBookRecords.map((guestBookItem) => renderGuestBookItem(guestBookItem))}
            <Modal
                className="guest-book-modal"
                title="Palikite atminimo įrašą"
                centered
                width={1200}
                footer={false}
                open={modalVisible}
                onOk={() => { setModalVisible(false); }}
                onCancel={() => { setModalVisible(false); }}>
                <div className="guest-book-modal-wrap">
                    {user?.isLoggedIn !== true
                        ? (
                            <div className="not-logged-in-text">
                                <p>Norėdami palikti įrašą atminimo knygoje prašome prisijungti, o jeigu neturite paskyros, ją galite nemokamai susikurti</p>
                                <Link to="/prisijungti" className="btn btn-primary" onClick={() => dispatch(setRemeberPathRequest(location.pathname))}>
                                    Prisijungti ar susikurti paskyrą <LoginOutlined />
                                </Link>
                            </div>
                        )
                        : (
                            <>
                                {displayForm
                                    ? (
                                        <Form
                                            form={form}
                                            ref={formRef}
                                            initialValues={{
                                                subject: '',
                                                message: '',
                                            }}
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            layout="vertical">
                                            <Divider />
                                            <Form.Item
                                                name="message"
                                                label="Atminimo knygos tekstas"
                                                rules={[{ required: true, message: 'Prašome įvesti tekstą' }, { min: 5, message: 'Tekstas negali būti trumpesnis nei 5 simboliai' }]}
                                                style={{ marginTop: '30px' }}>
                                                <TextArea rows={4} placeholder="Jūsų tekstas" />
                                            </Form.Item>

                                            <Form.Item>
                                                <Button type="primary" size="large" htmlType="submit" block className="mt-3 primary-btn-style" loading={user.isFetching}>
                                                    Paskelbti tekstą
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    )
                                    : <p className="success-message">Ačiū už Jūsų atminimą!</p>}
                            </>
                        )}
                    <Button type="secondary" onClick={() => { setModalVisible(false); }} className="map-modal-button">Uždaryti</Button>
                </div>
            </Modal>
        </div>
    );
};

GuestBook.propTypes = {
    disableAdmin: PropTypes.bool,
};

GuestBook.defaultProps = {
    disableAdmin: false,
};

export default GuestBook;
